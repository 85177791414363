





















































































































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import MathJaxMixin from '@/mixins/mathJax.mixin';
import { QUESION_TYPES, SUPPORTED_LANGS } from '../../../enums';
import { Model, ModelSpec, SpecQuestion } from '../../../types/exam.type';
import ViewMatchingColumns from '../../questions/matching/viewMatchingColumns.vue';
import ViewCaseStudySubQuestions from '../ViewCaseStudySubQuestions.component.vue';

@Component({
  components: {
    ViewMatchingColumns,
    ViewCaseStudySubQuestions,
  },
})
export default class ModelDetails extends mixins(MathJaxMixin) {
  @Prop() readonly model!: Model;

  questionTypesKeys = QUESION_TYPES;

  @Prop() readonly examLang!: string;

  openPanels: number[] = [];

  get appDrawerOpen() {
    return !this.$store.state.app.mini;
  }

  openSpecPanels(spec: ModelSpec[]) {
    return spec.map((itm, index) => index);
  }

  openQueestionPanels(questions: SpecQuestion[]) {
    return questions.map((itm, index) => index);
  }

  get nullModel() {
    return !this.model?.specs?.length;
  }

  SpecsDirection() {
    return this.examLang && this.examLang === SUPPORTED_LANGS.EN
      ? 'ltr'
      : 'rtl';
  }

  labelsDirection() {
    return this.$store.state.lang.lang === 'en' ? 'ltr' : 'rtl';
  }
}
