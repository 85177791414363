





























































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import DialogComponent from '@/components/dialog.component.vue';
import MathJaxMixin from '@/mixins/mathJax.mixin';
import servertranslate from '@/filters/server-translate';
import {
  Exam,
  Model,
  LabelsConfig,
  ExamIlos,
  ExamSubjects,
  ExamMatrixRecord,
  DifficultyLevelRanges,
  QuestionsTypes,

} from '../../types/exam.type';
import { APPIDS, Rights, EXPORT_EXAM } from '../../enums';
import Toaster from '../../mixins/toaster.mixin';
import MatrixBlueprintComponent from '../MatrixBlueprint.component.vue';
import SelectedCourse from '../SelectedCourse.component.vue';
import ExamModelPaper from './ExamModelPaper.component.vue';
import ModelDetails from './model-details/modelDetails.component.vue';
import ModelStatistics from './model-details/modelStatistics.component.vue';
import { Lang } from '../../types/general.type';

@Component({
  components: {
    SelectedCourse,
    ExamModelPaper,
    MatrixBlueprintComponent,
    ModelDetails,
    ModelStatistics,
    DialogComponent,
  },
})
export default class AddExamList extends mixins(Toaster, MathJaxMixin) {
  modelNumber: string = this.$route?.params.modelNumber;

  examId: string = this.$route?.params.examId;

  ilosData: ExamIlos[] = [];

  servertranslate = servertranslate;

  subjectsData: ExamSubjects[] = [];

  staticSubjectsData: ExamSubjects[] = [];

  examMatrix: ExamMatrixRecord[] = [];

  labelsConfig: LabelsConfig = {};

  difficulty: DifficultyLevelRanges[] = [];

  questionsTypes: QuestionsTypes[] = [];

  showDeleteConfirm = false;

  loadingDelete = false;

  message: Lang | null = null;

  courseId: string = this.$route?.params.courseId;

  tab = '';

  resErr = '';

  BackUrl = `/exam-generation/${this.$route?.params.courseId}/${this.$route?.params.examId}`;

  items: string[] = [
    'titles.ExamDetails',
    'titles.ExamPaper',
    'titles.ExamMatrix',
    'titles.EXAM_MODEL_STATISTICS',
  ];

  model: Model = {};

  exam: Exam = {
    _id: '',
    course: { courseCode: '' },
    matrix: [
      {
        ilos: [],
        lectureCount: 0,
        subject: '',
      },
    ],
    isDeleted: false,
    examInfo: {
      isSCU: false,
      isSectorsDistributed: false,
      questions: [],
      courseId: '',
      acadmicYearId: '',
      ownerId: '',
      ownerName: '',
      signature: '',
      countOfModels: 0,
      oldTotalTime: 0,
      isVerticalOrderd: false,
      examTypeName: '',
      acadmicYearName: '',
      semesterName: '',
      examLangName: '',
      shuffleName: '',
      difficultyName: '',
      difficulty: 0,
      faculty: '',
      department: '',
      examStatus: '',
      logo: '',
      examinerName: '',
      tFitness: 0,
      tQuestionsNumbers: 0,
      university: '',
      easyPercentage: 0,
      hardPercentage: 0,
      examType: '',
      examLang: '',
      semesterID: '',
      shuffle: '',
      training: '',
      examSignature: '',
      modelsMatch: '',
      examTitle: '',
      totalTime: 0,
      totalDegree: 0,
      exposureLimit: 0,
      instructorId: '',
      maxDifficulty: 100,
      mediumPercentage: 0,
      minDifficulty: 1,
      questionTypesCounts: [],
      examDate: '',
      addDegree: '',
      Degree: false,
    },
  };

  appId = APPIDS.EXAM_GENERATION;

  name = 'AddExam';

  loading = true;

  showCopyDialog = false;

  userRights: string[] = [];

  async mounted() {
    this.loadExamData();
  }

  get canDoAction() {
    return (
      this.userRights.includes(Rights.add)
      && this.exam.examInfo
      && this.exam.examInfo.examStatus === EXPORT_EXAM.MODELS_ADD
    );
  }

  async deleteModel() {
    try {
      this.loadingDelete = true;
      this.message = await ExamGenerationProvider.deleteModel(
        this.appId,
        this.courseId,
        this.examId,
        this.modelNumber,
      );
      this.loadingDelete = false;
      this.showDeleteConfirm = false;
      this.successToaster(this.message, { duration: 5000 });
      this.$router.push({ path: `/exam-generation/${this.courseId}/${this.examId}` });
    } catch (err) {
      this.loadingDelete = false;
      this.showDeleteConfirm = false;
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  get examTitle() {
    return this.exam.examInfo.examTitle;
  }

  editExam() {
    this.$router.push({
      name: 'edit-model',
      params: {
        courseId: this.courseId,
        examId: this.examId,
        modelNo: this.modelNumber,
      },
    });
  }

  async loadExamData() {
    try {
      const {
        labelsConfig,
        exam,
        ilos,
        subjects,
        difficultyLevelRanges,
        questionsTypes,
        userRights,
      } = await ExamGenerationProvider.getModelDetails(
        this.appId,
        this.courseId,
        this.examId,
        this.modelNumber,
      );
      this.exam = exam as Exam;
      this.labelsConfig = labelsConfig as LabelsConfig;
      this.userRights = userRights || [];
      this.model = this.getModel(exam?.models as Model[]) as Model;

      this.ilosData = (ilos || []) as ExamIlos[];
      this.staticSubjectsData = (subjects || []) as ExamSubjects[];
      this.subjectsData = this.staticSubjectsData.map((SubjectData) => ({
        ...SubjectData,
        subjectName: this.servertranslate(SubjectData.subjectName),
      }));

      this.examMatrix = (exam?.matrix || []) as ExamMatrixRecord[];
      this.difficulty = (difficultyLevelRanges
        || []) as DifficultyLevelRanges[];
      this.questionsTypes = (questionsTypes || []) as QuestionsTypes[];
      this.loading = false;
    } catch (err) {
      this.resErr = err;
      this.loading = false;
    }
  }

  get isSectorsDistributed(): boolean {
    return !!(this.exam.examInfo.isSCU && this.exam.examInfo.isSectorsDistributed);
  }

  getModel(models) {
    return models[0];
  }

  get noModel() {
    return !this.exam?.models?.length ?? 0;
  }

  copyModel(shuffle) {
    this.$router.push({
      name: 'copy-model',
      params: { modelNo: this.modelNumber },
      query: { shuffle },
    });
  }

  goBack() {
    this.$router.push({ path: this.BackUrl });
    // this.$router.go(-1);
  }
}
