











































































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import PieChartComponent from '@/shared/pieChart.component.vue';
import {
  ChartsData,
  DifficultyLevelRanges, ExamIlos, ExamSubjects, ModelSpec, QuestionsTypes, SpecQuestion,
} from '../../../types/exam.type';

@Component({
  components: {
    PieChartComponent,
  },
})
export default class ModelStatistics extends Vue {
    @Prop({
      type: Array,
      default: () => [],
    }) modelSpecs!: ModelSpec[];

    @Prop({
      type: Array,
      default: () => [],
    }) ilosData!: ExamIlos[];

    @Prop({
      type: Array,
      default: () => [],
    }) subjectsData!: ExamSubjects[];

    @Prop({
      type: Array,
      default: () => [],
    }) difficulty!: DifficultyLevelRanges[];

    @Prop({
      type: Array,
      default: () => [],
    }) questionsTypes!: QuestionsTypes[];

    questions: SpecQuestion[] = [];

    subjectData: ChartsData[] = [];

    difficultyData: ChartsData[] = [];

    questionTypeData: ChartsData[] = [];

    ILOsData: ChartsData[] = [];

    loading = true;

    mounted() {
      this.modelQuestions();
      this.calcSubjectStatus();
      this.calcDiffStatus();
      this.calcTypeStatus();
      this.calcILOStatus();
      this.loading = false;
    }

    calcSubjectStatus() {
      const result = this.questions.reduce((acc, question) => {
        if (acc[question.subjId]) {
          acc[question.subjId].degrees += question.degree;
        } else {
          acc[question.subjId] = {
            name: this.getSubjectName(question.subjId),
            degrees: question.degree,
          };
        }
        return acc;
      }, {});
      this.subjectData = Object.values(result);
    }

    getSubjectName(subjId) {
      return this.subjectsData.find((subj) => subj.subjectId === subjId)?.subjectName;
    }

    calcDiffStatus() {
      const result = this.questions.reduce((acc, question) => {
        if (acc[this.getDifficultyIdByValue(question.difficulty)]) {
          acc[this.getDifficultyIdByValue(question.difficulty)].degrees += question.degree;
        } else {
          acc[this.getDifficultyIdByValue(question.difficulty)] = {
            name: this.getDiffName(this.getDifficultyIdByValue(question.difficulty)),
            degrees: question.degree,
          };
        }
        return acc;
      }, {});
      this.difficultyData = Object.values(result);
    }

    getDiffName(diffId) {
      return this.difficulty.find((diff) => diff.id === diffId)?.name;
    }

    calcTypeStatus() {
      const result = this.questions.reduce((acc, question) => {
        if (acc[question.type_id]) {
          acc[question.type_id].degrees += question.degree;
        } else {
          acc[question.type_id] = {
            name: this.getTypeName(question.type_id),
            degrees: question.degree,
          };
        }
        return acc;
      }, {});
      this.questionTypeData = Object.values(result);
    }

    getTypeName(typeId) {
      return this.questionsTypes.find((ques) => ques.id === typeId)?.name;
    }

    calcILOStatus() {
      const result = this.questions.reduce((acc, question) => {
        if (acc[question.iloId]) {
          acc[question.iloId].degrees += question.degree;
        } else {
          acc[question.iloId] = {
            name: this.getILOName(question.iloId),
            degrees: question.degree,
          };
        }
        return acc;
      }, {});
      this.ILOsData = Object.values(result);
    }

    getILOName(iloId) {
      return this.ilosData.find((ilo) => ilo.iloId === iloId)?.iloName;
    }

    modelQuestions() {
      this.modelSpecs.forEach((spec) => {
        this.questions.push(...spec.questions);
      });
    }

    getDifficultyIdByValue(difficulty) {
      return this.difficulty
        .filter((diff) => ((difficulty >= diff.min) && (difficulty < diff.max)))[0].id;
    }
}
